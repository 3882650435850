import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Divider from '../components/Divider'
import FadeIn from '../components/FadeIn'

const ProcedureInfoPage = () => {
  return (
    <Layout>
      <SEO title="Procedure Info" />
      <FadeIn duration={0.5} id="intro" elementType="section">
        <div className="lede" >
          <p>
            Carefully following aftercare instructions is a must to best
            maintain pigment colors, avoid the possibility of infection, and
            protect the permanent makeup.
          </p>
          <p>
            Some swelling and tenderness may occur on eyeliners and full lip
            colors (Eyebrow swelling is rare). Fresh permanent makeup should be
            expected to look too dark and thick initially, then soften and
            lighten in about 3 to 4 days, and will then heal for several more
            weeks. Touchups are usually required to perfect pigment colors and
            shapes. They may also be needed over the years to brighten or darken
            softened colors.
          </p>
        </div>
      </FadeIn>
      <Divider />
      <FadeIn elementType="section" id="pre-procedure">
        <div className="text">
          <h2>Pre-procedure info</h2>
        </div>
        <div className="row">
          <div className="text">
            <p>
              All permanent cosmetic procedures are multi-session processes. You
              are required to come back for at least one touch-up visit before
              it can be determined that your work is complete. Touch-up visits
              are scheduled at 4 week intervals.
            </p>
            <p>
              Be prepared for the color intensity of our procedure to be
              significantly sharper, brighter or darker than what is expected
              for the final outcome. It will take time for this transition,
              based on how quickly the outer layer of your skin exfoliates.
            </p>
            <p>
              Since delicate skin or sensitive areas may swell slightly or
              redden, some clients feel it best not to make any social plans for
              a few days following any procedure.
            </p>
            <p>
              Wear your normal makeup and bring your lip or brow pencils to the
              office on the day of the procedure.
            </p>
            <p>
              Any tweezing or waxing should be done at least 48 hours prior to
              the procedure; electrolysis no less than five days before. Do not
              resume any method of hair removal for at least two weeks.
            </p>
            <p>
              Any tweezing or waxing should be done at least 48 hours prior to
              the procedure; electrolysis no less than five days before. Do not
              resume any method of hair removal for at least two weeks.
            </p>
          </div>
          <div className="text">
            <p>
              Any eyelash or eyebrow tinting or eyelash curling should be done
              no sooner than 48 hours before or two weeks after the procedure.
            </p>
            <p>
              Do not wear contact lenses during or immediately following the
              eyeliner procedure. Remember to bring your glasses. You may resume
              wearing your contact lenses as soon as your eyes return to their
              pre-tattooed condition which is usually three days.
            </p>
            <p>
              You may want to have someone drive you to and from your
              appointment.
            </p>
            <p>
              If you are having lip procedures and have any history of cold
              sores/fever blisters/herpes simplex, you will be required to
              contact your physician to obtain the proper prescription
              medication to prevent such outbreaks.
            </p>
            <p>
              Refrain from the use of aspirin, aspirin-containing medications,
              Ibuprofen, Coumadin, fish oil, or vitamin E for 7 days before and
              2 days after any procedure. Alcohol should be avoided for a day or
              two prior to any procedure.{' '}
              <em>
                No medication should be discontinued without first consulting
                your physician.
              </em>
            </p>
          </div>
        </div>
      </FadeIn>
      <FadeIn elementType="section" id="aftercare">
        <div className="text">
          <h2>Immediate aftercare</h2>
          <p>
            Please keep all treated areas clean and moist. Do not pick, scratch,
            or rub while the area is healing. Loss of color and/or infection may
            result. Apply vitamin A&D ointment for 7 to 10 days for lips and
            eyebrow procedures, and 2 days for eyeliner, always using a clean
            cotton swab. Do not use Aloe Vera during this time. Avoid direct
            sunlight exposure for 10 to 14 days. Stay out of hot tubs and
            refrain from swimming for 7 to 10 days.
          </p>
        </div>
        <div className="text">
          <h2>Long-term aftercare</h2>
          <p>
            To minimize color loss, apply a 30+ sun block (the stronger the
            better) prior to exposure to the sun or tanning bed. Be aware that
            lasers are damaging to all cosmetic tattooing pigments. If you are
            planning a chemical peel, MRI or other medical procedure, please
            inform your physician of your cosmetic tattoo.
          </p>
        </div>
      </FadeIn>
      <Divider />
    </Layout>
  )
}
export default ProcedureInfoPage
